"use client";

import type { z } from "zod";
import { PhoneInput } from "@/components/PhoneInput/PhoneInput";
import { Button } from "@/components/ui/Button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/Form";
import { Input } from "@/components/ui/Input";
import { Loader, LoadingOverlay } from "@/components/ui/Loader/Loader";
import { Text } from "@/components/ui/Text";
import { useToast } from "@/components/ui/use-toast";
import { userDetailSchema } from "@/server/api/validationSchemas";
import { defaultFormErrorToast } from "@/utils/formErrorToast";
import { type PageContent } from "@/utils/i18n";
import { trpc } from "@/utils/TrpcProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSession } from "next-auth/react";
import { useForm } from "react-hook-form";
import { useLocaleProvider } from "../../app/[lang]/LocaleProvider";
const t = {
  header: {
    en: "Welcome to Vouch!",
    no: "Velkommen til Vouch!",
    dk: "Velkommen til Vouch!"
  },
  description: {
    en: "We just need a bit more information to get you started.",
    no: "Vi trenger bare litt mer informasjon for å komme i gang.",
    dk: "Vi trenger bare litt mer informasjon for å komme i gang."
  },
  firstName: {
    no: "Fornavn *",
    en: "First name *",
    dk: "Fornavn *"
  },
  lastName: {
    en: "Last name *",
    no: "Etternavn *",
    dk: "Etternavn *"
  },
  phone: {
    en: "Phone (optional)",
    no: "Telefon (valgfritt)",
    dk: "Telefon (valgfrit)"
  },
  save: {
    en: "Save",
    no: "Lagre",
    dk: "Gem"
  }
} satisfies PageContent;
const formSchema = userDetailSchema;
type Form = z.infer<typeof formSchema>;
export function BasicUserForm({
  onSave
}: {
  onSave: () => void;
}) {
  const session = useSession();
  const user = session.data?.user;
  const {
    lang
  } = useLocaleProvider();
  const {
    toast
  } = useToast();
  const context = trpc.useUtils();
  const editUser = trpc.user.edit.useMutation({
    async onSuccess() {
      await context.user.get.invalidate();
      await context.user.getUserWithRoles.invalidate();
      await context.user.getUserFromSession.invalidate();
      onSave();
    },
    onError: er => {
      toast({
        title: er.message,
        variant: "destructive"
      });
    }
  });
  const form = useForm<Form>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone || undefined
    }
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    editUser.mutate(values);
  }
  if (!user) {
    return <LoadingOverlay loading={true} />;
  }
  return <div className="p-8" data-sentry-component="BasicUserForm" data-sentry-source-file="BasicUserForm.tsx">
      <div className="flex w-full flex-col overflow-x-hidden">
        {editUser.isLoading && <Loader />}
        <Form {...form} data-sentry-element="Form" data-sentry-source-file="BasicUserForm.tsx">
          <Text.H4 className="mb-4" data-sentry-element="unknown" data-sentry-source-file="BasicUserForm.tsx">{t.header[lang]}</Text.H4>
          <Text.P className="mb-4" data-sentry-element="unknown" data-sentry-source-file="BasicUserForm.tsx">{t.description[lang]}</Text.P>
          <form onSubmit={form.handleSubmit(onSubmit, defaultFormErrorToast)} className="relative mt-4 flex w-full flex-col gap-4">
            <div className="flex flex-wrap gap-2">
              <FormField control={form.control} name="firstName" render={({
              field
            }) => <FormItem className="w-0 flex-1">
                    <FormLabel>{t.firstName[lang]}</FormLabel>
                    <FormControl>
                      <Input placeholder="John" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="BasicUserForm.tsx" />
              <FormField control={form.control} name="lastName" render={({
              field
            }) => <FormItem className="w-0 flex-1">
                    <FormLabel>{t.lastName[lang]}</FormLabel>
                    <FormControl>
                      <Input placeholder="Doe" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>} data-sentry-element="FormField" data-sentry-source-file="BasicUserForm.tsx" />
            </div>
            <FormField control={form.control} name="phone" render={({
            field
          }) => <FormItem>
                  <FormLabel>{t.phone[lang]}</FormLabel>
                  <FormControl>
                    <PhoneInput value={field.value} setValue={field.onChange} onBlur={field.onBlur} />
                  </FormControl>

                  <FormMessage />
                </FormItem>} data-sentry-element="FormField" data-sentry-source-file="BasicUserForm.tsx" />

            <Button type="submit" className="self-end" icon="rightArrow" disabled={!form.formState.isValid} data-sentry-element="Button" data-sentry-source-file="BasicUserForm.tsx">
              {t.save[lang]}
            </Button>
          </form>
        </Form>
      </div>
    </div>;
}