"use client";

import { useEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { UserMenu } from "@/app/[lang]/UserMenu";
import { BasicUserForm } from "@/components/BasicUserForm/BasicUserForm";
import { TimezoneSelectorForm } from "@/components/TimezoneSelectorForm/TimezoneSelectorForm";
import { SimpleDialog } from "@/components/ui/dialog";
import { Text } from "@/components/ui/Text";
import { useIsClient } from "@/hooks/useIsClient";
import { type SupportedLanguage } from "@/utils/i18n";
import { cn } from "@/utils/tw";
import { motion } from "framer-motion";
import { useSession } from "next-auth/react";
import { StickyFooterContent } from "../StickyFooterContent";
import { Icon } from "../ui/Icon";
import { ContextButton } from "./ContextButton";
import { MobileNav } from "./MobileNav";
import { UseCaseSlider } from "./UseCaseSlider";
export const heightTopbar = 90; // if we are sliding out a nav bar at the top of the screen, this will be it's height
const heightFooter = 90; // if we are sliding out a nav bar at the top of the screen, this will be it's height

export type NavbarTheme = keyof typeof themeStyles;
export const themeStyles = {
  beige: {
    nav: "bg-beige-3 text-purple-1",
    link: {
      active: "text-purple-5",
      inactive: "text-purple-7"
    },
    button: {
      primary: "bg-yellow text-purple",
      secondary: "bg-yellow text-purple-1"
    },
    border: "border-purple-7",
    divide: "divide-purple-7"
  },
  green: {
    nav: "dark bg-green dark:text-white",
    link: {
      active: "dark:text-green-3",
      inactive: "dark:text-green-3/60"
    },
    button: {
      primary: "bg-green-3 dark:text-purple",
      secondary: "bg-transparent dark:text-white"
    },
    border: "border-purple-6",
    divide: "divide-purple-6"
  },
  purple: {
    nav: "dark bg-purple dark:text-purple-6",
    link: {
      active: "dark:text-purple-5",
      inactive: "dark:text-purple-3"
    },
    button: {
      primary: "bg-purple-5 dark:text-white",
      secondary: "bg-transparent dark:text-white"
    },
    border: "border-purple-6",
    divide: "divide-purple-6"
  }
};
export const Nav = ({
  onChangeEnded,
  override,
  theme,
  noFooter
}: {
  lang: SupportedLanguage;
  onChangeEnded?: (key: string) => void;
  override?: string | null;
  theme?: NavbarTheme;
  noFooter?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const router = useRouter();
  const [isUseCasesOpen, setUseCasesOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setUseCasesOpen(true);
  };
  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setUseCasesOpen(false);
    }, 300);
  };
  useEffect(() => {
    let lastScroll = 0;
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      setIsVisible(currentScroll < lastScroll || currentScroll <= heightTopbar);
      lastScroll = currentScroll;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const pathName = usePathname();
  const current = override || pathName;
  const isUrlUseCases = current === "/" || current === "/companies" || current === "/candidates";
  const {
    data: session
  } = useSession();
  const user = session?.user;
  const [hasSaved, setHasSaved] = useState(false);
  const [themeStyle, setThemeStyle] = useState(themeStyles.beige);
  useEffect(() => {
    setThemeStyle(themeStyles[theme ?? "beige"]);
  }, [theme]);
  const {
    country
  } = useLocaleProvider();
  const isClient = useIsClient();
  const activeTab = useMemo(() => {
    if (current === "/companies") return "Company";
    if (current === "/candidates") return "Candidate";
    if (current === "/") return "Voucher";
    return "";
  }, [current]);
  return <>
      {user && (user.firstName.trim() === "" || user.lastName.trim() === "" ? <SimpleDialog open={!hasSaved}>
            <BasicUserForm onSave={() => {
        setHasSaved(true);
      }} />
          </SimpleDialog> : <TimezoneSelectorForm />)}
      <motion.header className={cn(`top-0 z-40 w-dvw transition-all`, themeStyle.nav, {
      fixed: true
    })} animate={{
      y: isVisible ? 0 : -heightTopbar
    }} transition={{
      duration: 0.2,
      delay: 0,
      ease: "easeInOut"
    }} data-sentry-element="unknown" data-sentry-source-file="Nav.tsx">
        <div className="container flex items-center justify-between gap-8 py-6">
          <div className="flex gap-4">
            <div className="flex items-center justify-center">
              <ContextButton className="max-lg:hidden" data-sentry-element="ContextButton" data-sentry-source-file="Nav.tsx" />
              <Link href="/" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
                <Image src="/logo.svg" alt="Vouch" width={108} height={24} className={theme === "beige" ? "" : "brightness-100 invert"} data-sentry-element="Image" data-sentry-source-file="Nav.tsx" />
              </Link>
            </div>
            <div className="flex items-center justify-center gap-10 rounded-full max-lg:hidden lg:ml-8">
              <Link href="/discovery" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
                <Text.P className={cn(current === "/discovery" || current.startsWith("/listings") ? themeStyle.link.active : themeStyle.link.inactive, {
                hidden: isClient && country !== "NO"
              })} data-sentry-element="unknown" data-sentry-source-file="Nav.tsx">
                  Explore listings
                </Text.P>
              </Link>
              <div className="cursor-pointer" onMouseEnter={handleMouseEnter} onClick={() => setUseCasesOpen(true)} onMouseLeave={handleMouseLeave}>
                <Text.P className={isUseCasesOpen || isUrlUseCases ? themeStyle.link.active : themeStyle.link.inactive} data-sentry-element="unknown" data-sentry-source-file="Nav.tsx">
                  <span className="text-black/60">I&apos;m a</span> {activeTab || "..."}{" "}
                  {activeTab && <Icon size="x-small" icon="downChevron" />}
                </Text.P>
              </div>
              <Link href="/pricing" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
                <Text.P className={current === "/pricing" ? themeStyle.link.active : themeStyle.link.inactive} data-sentry-element="unknown" data-sentry-source-file="Nav.tsx">
                  Pricing
                </Text.P>
              </Link>
              <Link href="/about" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
                <Text.P className={current === "/about" ? themeStyle.link.active : themeStyle.link.inactive} data-sentry-element="unknown" data-sentry-source-file="Nav.tsx">
                  About
                </Text.P>
              </Link>
            </div>
          </div>
          <MobileNav isSheetOpen={isSheetOpen} setSheetOpen={setSheetOpen} theme={theme ?? "beige"} current={current} router={router} onChangeEnded={onChangeEnded} data-sentry-element="MobileNav" data-sentry-source-file="Nav.tsx" />
          <div className="relative flex items-center gap-4 max-lg:hidden">
            <UserMenu className={themeStyle.button.primary} data-sentry-element="UserMenu" data-sentry-source-file="Nav.tsx" />
          </div>
        </div>
      </motion.header>
      {isUseCasesOpen && <div className={cn(`fixed z-30 hidden w-full overflow-hidden pt-[90px] lg:block lg:pt-20`, themeStyle.nav)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <UseCaseSlider initialActiveKey={pathName || "/"} onChange={key => {
        if (key !== pathName) {
          router.push(key);
        }
        onChangeEnded?.(key);
      }} theme={theme} />
        </div>}
      {!noFooter && <motion.footer className={cn(`bottom-0 z-20 w-dvw transition-all lg:hidden`, themeStyle.nav, {
      fixed: true
    })} animate={{
      y: isVisible ? 0 : heightFooter
    }} transition={{
      duration: 0.2,
      delay: 0,
      ease: "easeInOut"
    }}>
          <div className="container w-full py-4">
            <StickyFooterContent theme={themeStyle.button.primary} />
          </div>
        </motion.footer>}
    </>;
};