"use client";

import type { LottieComponentProps } from "lottie-react";
import { useEffect, useState } from "react";
import { cn } from "@/utils/tw";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Lottie from "lottie-react";
export const Animation = ({
  url,
  ...props
}: Omit<LottieComponentProps, "animationData"> & {
  url: string;
}) => {
  const [animationData, setAnimationData] = useState<unknown>();
  useEffect(() => {
    fetch(url).then(data => data.json()).then(json => {
      setAnimationData(json);
    }).catch(console.error);
  }, [url]);
  if (!animationData) {
    return null;
  }
  return <Lottie animationData={animationData} {...props} data-sentry-element="Lottie" data-sentry-component="Animation" data-sentry-source-file="Animation.tsx" />;
};
export function LottiePlayer(props: {
  className?: string;
  src: string; // path to the json file in the public folder // usually something like "/img/animation/fileName.json"
}) {
  return <Player autoplay loop src={props.src} className={cn("h-80 w-80", props.className)} data-sentry-element="Player" data-sentry-component="LottiePlayer" data-sentry-source-file="Animation.tsx">
      <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} data-sentry-element="Controls" data-sentry-source-file="Animation.tsx" />
    </Player>;
}