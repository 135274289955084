"use client";

import { useState } from "react";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import { useAuth } from "@/components/SignIn/SignInProvider";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/Button";
import { Icon } from "@/components/ui/Icon";
import { Popover } from "@/components/ui/Popover";
import { Text } from "@/components/ui/Text";
import { Tooltip } from "@/components/ui/Tooltip";
import { type PageContent } from "@/utils/i18n";
import { trpc } from "@/utils/TrpcProvider";
import { cn } from "@/utils/tw";
import { uniqBy } from "lodash-es";
import { signOut } from "next-auth/react";
import { useLocaleProvider } from "./LocaleProvider";
const t = {
  getStarted: {
    en: "Get Started",
    no: "Kom i gang",
    dk: "Kom i gang"
  },
  change: {
    no: "Bytt konto",
    en: "Change account",
    dk: "Skift konto",
    business: {
      no: "Bytt til bedrift",
      en: "Change to business",
      dk: "Skift til virksomhed"
    },
    private: {
      no: "Bytt til privat",
      en: "Change to private",
      dk: "Skift til privat"
    }
  },
  create: {
    en: "New Company",
    no: "Ny bedrift",
    dk: "Ny virksomhed"
  },
  logOut: {
    en: "Sign Out",
    no: "Logg ut",
    dk: "Log ud"
  },
  roles: {
    private: {
      en: "Private",
      no: "Privat",
      dk: "Privat"
    },
    ADMIN: {
      en: "Admin",
      no: "Admin",
      dk: "Admin"
    },
    EDITOR: {
      en: "Editor",
      no: "Redigeringstilgang",
      dk: "Redigeringstilgang"
    },
    OWNER: {
      en: "Owner",
      no: "Eier",
      dk: "Ejer"
    },
    OBSERVER: {
      en: "Observer",
      no: "Observer",
      dk: "Observer"
    }
  },
  menu: {
    account: {
      no: "Kontoinnstillinger",
      en: "Account settings",
      dk: "Kontoindstillinger"
    },
    profile: {
      no: "Profil",
      en: "Profile",
      dk: "Profil"
    },
    settings: {
      no: "Kontoinnstillinger",
      en: "Account settings",
      dk: "Kontoindstillinger"
    },
    support: {
      en: "Talk to support",
      no: "Snakk med support",
      dk: "Snak med support"
    }
  }
} satisfies PageContent;
export function UserMenu({
  expanded = true,
  onButtonClick,
  className
}: {
  variant?: "dark" | "light";
  expanded?: boolean;
  onButtonClick?: () => void;
  className?: string;
}) {
  const {
    lang
  } = useLocaleProvider();
  const {
    data: user,
    isLoading
  } = trpc.user.getUserWithRoles.useQuery(undefined, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false
  });
  const {
    setShowAuth
  } = useAuth();
  const params = useParams();
  const businessSlug = params?.businessId as string;
  const router = useRouter();
  const [open, setOpen] = useState(false);
  if (isLoading) {
    return <div className="relative flex gap-2">
        <Icon icon="loading" className="animate-spin" variant="gray" />
      </div>;
  }
  if (!user) {
    return <div className="flex gap-4">
        <Button rounded onClick={() => {
        setShowAuth({
          type: "default"
        });
        onButtonClick?.();
      }} className={className}>
          {t.getStarted[lang]}
        </Button>
      </div>;
  }
  const businessRoles = uniqBy(user.companyRoles.map(role => ({
    id: role.company?.slug || role.listing?.company.slug || "",
    name: role.company?.name || role.listing?.company.name || "",
    image: role.company?.logoUrl || role.listing?.company.logoUrl || "",
    role: role.level
  })).sort((a, b) => b.name.localeCompare(a.name)).sort(el => ["ADMIN", "OWNER"].includes(el.role) ? -1 : 1), e => e.id);
  return <>
      <div className="relative">
        <Popover hideClose hideArrow open={open} onOpenChange={setOpen} wrapperClassName={"z-50 mx-4 focus-visible:outline-none "} sideOffset={10} content={<div className="relative w-64 max-w-full rounded-xl border border-black/10 bg-beige-3 shadow-none transition-all">
              <RoleCard className="pb-4" {...businessRoles.find(el => el.id === businessSlug) || user} role={t.roles[businessRoles.find(el => el.id === businessSlug)?.role || "private"][lang]} email={user.email} icon={<Icon icon="upChevron" variant="gray" className="z-10" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(false);
        }} />} />
              <div className="mx-3 flex flex-row justify-stretch gap-2">
                <div className="flex flex-1">
                  <Link href="/profile/candidate/settings" className="w-full">
                    <Button size="sm" variant="ghost" className="flex w-full flex-col gap-2 border border-black/10 p-3">
                      <Icon icon="settings" />
                      <Text.P>Settings</Text.P>
                    </Button>
                  </Link>
                </div>
                <div className="flex flex-1">
                  <a id="open-intercom" className="w-full">
                    <Button size="sm" variant="ghost" className="flex w-full flex-col gap-2 border border-black/10 p-3">
                      <Icon icon="support" />
                      <Text.P>Support</Text.P>
                    </Button>
                  </a>
                </div>
              </div>
              <a id="logout" onClick={() => signOut()} className="cursor-pointer" tabIndex={0}>
                <div className="px-4 py-4 text-center text-muted underline">{t.logOut[lang]}</div>
              </a>
              {businessSlug && <RoleCard {...user} onClick={() => {
          router.push("/profile/candidate");
        }} role={t.roles.private[lang]} email="" className="border-t border-black/10" icon={<div className="flex items-end rounded bg-black/10 p-1 opacity-0 transition-opacity group-hover:opacity-100">
                      <Icon icon="upRightArrow" className="p-1" />
                    </div>} />}
              {businessRoles.filter(businessRole => businessRole.id !== businessSlug).map(businessRole => {
          return <RoleCard {...businessRole} onClick={() => {
            router.push(`/profile/business/${businessRole.id}`);
          }} role={t.roles[businessRole.role][lang]} key={businessRole.id} className="border-t border-black/10" icon={<div className="flex items-end rounded bg-black/10 p-1 opacity-0 transition-opacity group-hover:opacity-100">
                          <Icon icon="upRightArrow" className="p-1" />
                        </div>} />;
        })}
              <Link href="/onboarding">
                <div className="flex items-center gap-4 border-t border-black/10 px-4 py-4 text-muted transition-all hover:rounded-b-xl hover:bg-gray">
                  <Icon icon="plus" className="border-[2px] border-black/10 p-0.5 text-muted" />
                  <Text.P className="text-purple-1">{t.create[lang]}</Text.P>
                </div>
              </Link>
            </div>} data-sentry-element="Popover" data-sentry-source-file="UserMenu.tsx">
          {expanded ? <div className="flex gap-[2px] rounded-full">
              <div className="flex items-center gap-2 rounded-l-full bg-black/5 p-2 hover:bg-black/10 dark:bg-white/5 dark:hover:bg-white/10">
                <Avatar className="h-[28px] w-[28px] cursor-pointer">
                  <AvatarImage src={(businessRoles.find(el => el.id === businessSlug) || user).image || ""} alt={user.name + " avatar"} />
                  <AvatarFallback>
                    {`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
                  </AvatarFallback>
                </Avatar>
                <Icon icon="downChevron" size="small" className="dark:text-white" />
              </div>
              <Link href={businessSlug ? `/profile/business/${businessRoles[0]!.id}` : `/profile/candidate`} className="flex flex-col items-center justify-center rounded-r-full bg-black/5 p-2 hover:bg-black/10 dark:bg-white/5 dark:hover:bg-white/10">
                <Tooltip content={businessSlug ? "Company portal" : "My profile"} contentClass="z-50">
                  <Icon icon="upRightArrow" className="pr-1 dark:text-white" />
                </Tooltip>
              </Link>
            </div> : <Avatar className="h-10 w-10 cursor-pointer">
              <AvatarImage src={user.image} alt={user.name + " avatar"} />
              <AvatarFallback>
                {`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
              </AvatarFallback>
            </Avatar>}
        </Popover>
      </div>
    </>;
}
const RoleCard = (props: {
  selected?: boolean;
  onClick?: () => void;
  id: string;
  className?: string;
  name: string;
  email?: string;
  image: string | null;
  role: string;
  icon?: React.ReactNode;
}) => {
  const getRouterLink = () => {
    return props.role === "Private" ? "/profile/candidate" : `/profile/business/${props.id}`;
  };
  return <div key={props.id} className={cn("group flex cursor-pointer items-center justify-between gap-2 px-0 py-3", props.className)} data-sentry-component="RoleCard" data-sentry-source-file="UserMenu.tsx">
      <Link href={getRouterLink()} className="z-0 mx-4 flex w-full flex-row items-center gap-2" data-sentry-element="Link" data-sentry-source-file="UserMenu.tsx">
        <Avatar className="h-8 w-8 self-start" data-sentry-element="Avatar" data-sentry-source-file="UserMenu.tsx">
          <AvatarImage src={props.image || ""} alt={props.name + " avatar"} className="object-contain" data-sentry-element="AvatarImage" data-sentry-source-file="UserMenu.tsx" />
          <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="UserMenu.tsx">
            {props.name.split(" ").map(el => el.charAt(0)).join("").toUpperCase()}
          </AvatarFallback>
        </Avatar>

        <div className="flex flex-1 flex-col space-y-1 overflow-hidden">
          <Text.P data-sentry-element="unknown" data-sentry-source-file="UserMenu.tsx">{props.name}</Text.P>
          {props.role === "Private" && props.email && <Text.P muted className="truncate">
              {props.email}
            </Text.P>}
        </div>

        {props.icon}
      </Link>
    </div>;
};