"use client";

import type { PageContent } from "@/utils/i18n";
import type { TCountryCode } from "countries-list";
import type { CountryCode } from "libphonenumber-js";
import React, { useMemo } from "react";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { CountrySelectOptions } from "@/utils/i18n";
import { isSupportedSmsCountry } from "@/utils/smsCountries";
import { cn } from "@/utils/tw";
import { getEmojiFlag } from "countries-list";
import { getCountryCallingCode } from "libphonenumber-js";
import { usePhoneInput } from "react-international-phone";
import { ComboBoxDynamic } from "../ui/formInputs/Combobox";
import { PureInput } from "../ui/Input";
const t = {
  placeholderCountry: {
    en: "Country code",
    no: "Landkode",
    dk: "Landekode"
  },
  placeholder: {
    no: "00 00 00 00",
    en: "000 000 0000",
    dk: "00 00 00 00"
  },
  disabled: {
    en: "Country is not supported yet",
    no: "Landet er ikke støttet ennå",
    dk: "Landet er endnu ikke understøttet"
  }
} satisfies PageContent;
export const PhoneInput = ({
  setValue,
  value,
  disabled,
  placeholder,
  onBlur,
  countySelectorClassName,
  inputClassName
}: {
  value?: string | null;
  setValue: (val?: string) => void;
  defaultCountry?: TCountryCode;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: () => void;
  countySelectorClassName?: string;
  inputClassName?: string;
}) => {
  const {
    lang,
    country
  } = useLocaleProvider();
  const {
    inputValue,
    country: phoneCountry,
    setCountry,
    handlePhoneValueChange,
    inputRef
  } = usePhoneInput({
    defaultCountry: country.toLowerCase(),
    value: value || undefined,
    disableDialCodeAndPrefix: true,
    disableDialCodePrefill: true,
    onChange: ({
      phone,
      inputValue
    }) => {
      if (!inputValue) {
        setValue("");
      } else {
        setValue(phone);
      }
    }
  });
  const phoneCountryOptions = useMemo(() => {
    return CountrySelectOptions.filter(c => isSupportedSmsCountry(c.value)).map(c => ({
      label: `${c.label} +${getCountryCallingCode(c.value as CountryCode)}`,
      value: c.value,
      disabledTooltip: t.disabled[lang],
      shortLabel: `${getEmojiFlag(c.value) || c._country.name}`
    })).sort((a, b) => a.label.localeCompare(b.label, lang));
  }, [lang]);
  const defaultCountryCode = phoneCountry?.iso2.toUpperCase() || "no";
  return <div className="flex w-full flex-wrap items-stretch gap-2" data-sentry-component="PhoneInput" data-sentry-source-file="PhoneInput.tsx">
      <ComboBoxDynamic triggerClassName={cn("h-auto w-16 md:h-full", countySelectorClassName)} wrapperClassName="h-auto" options={phoneCountryOptions} placeholder={t.placeholderCountry[lang]} searchPlaceholder={t.placeholderCountry[lang]} value={defaultCountryCode} onSelectCallback={option => {
      if (option) {
        setCountry(option.value.toLowerCase());
      }
    }} constructTriggerLabel={option => option.shortLabel} data-sentry-element="ComboBoxDynamic" data-sentry-source-file="PhoneInput.tsx" />
      <PureInput placeholder={placeholder || t.placeholder[lang]} ref={inputRef} value={inputValue} onChange={handlePhoneValueChange} className={cn("w-0 min-w-24 flex-1", inputClassName)} disabled={disabled} onBlur={onBlur} data-sentry-element="PureInput" data-sentry-source-file="PhoneInput.tsx" />
    </div>;
};