"use client";

import type { NavbarTheme } from "@/components/Nav/Nav";
import { useEffect, useRef, useState } from "react";
import { Animation } from "@/components/ui/Animation";
import { Text } from "@/components/ui/Text";
import { cn } from "@/utils/tw";
const themeStyles = {
  beige: {
    background: {
      active: "bg-yellow text-purple",
      inactive: "bg-beige-2 text-purple",
      hover: "hover:bg-yellow hover:text-purple"
    }
  },
  green: {
    background: {
      active: "bg-green-3 text-purple",
      inactive: "bg-[#073a27] text-white",
      hover: "hover:bg-green-3 hover:text-purple"
    }
  },
  purple: {
    background: {
      active: "bg-purple-5 text-white",
      inactive: "bg-purple-2 text-white",
      hover: "hover:bg-purple-5 hover:text-white"
    }
  }
};
export const UseCaseSlider = ({
  initialActiveKey,
  onChange,
  theme
}: {
  initialActiveKey: string;
  onChange: (key: string) => void;
  theme?: NavbarTheme;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // scroll to the initial active key
    const el = ref.current?.querySelector("[data-id='" + initialActiveKey + "']");
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }, [initialActiveKey]);
  const [themeStyle, setThemeStyle] = useState(themeStyles.beige);
  useEffect(() => {
    setThemeStyle(themeStyles[theme ?? "beige"]);
  }, [theme]);
  return <div className="container flex snap-x gap-4 overflow-y-hidden overscroll-y-none text-purple" ref={ref} data-sentry-component="UseCaseSlider" data-sentry-source-file="UseCaseSlider.tsx">
      <div className="relative mx-0 cursor-pointer snap-center py-4" tabIndex={0} onClick={() => {
      onChange("/");
    }} data-id="/">
        <div className={cn("squircle h-[172px] w-60 p-5", initialActiveKey === "/" ? themeStyle.background.active : [themeStyle.background.inactive, themeStyle.background.hover])}>
          <Text.P data-sentry-element="unknown" data-sentry-source-file="UseCaseSlider.tsx">Voucher</Text.P>
        </div>
        <div className="pointer-events-none absolute bottom-[-10px] left-10">
          <Animation url={"/img/sparkle.json"} className="w-[280px] lg:mx-0" data-sentry-element="Animation" data-sentry-source-file="UseCaseSlider.tsx" />
        </div>
      </div>

      <div className="relative mx-0 cursor-pointer snap-center py-4" tabIndex={0} onClick={() => {
      onChange("/companies");
    }} data-id="/companies">
        <div className="relative mx-0 pb-8 text-purple md:px-2">
          <div className={cn("squircle h-[172px] w-60 p-5", {
          [themeStyle.background.active]: initialActiveKey === "/companies",
          [themeStyle.background.inactive]: initialActiveKey !== "/companies",
          [themeStyle.background.hover]: initialActiveKey !== "/companies"
        })}>
            <Text.P data-sentry-element="unknown" data-sentry-source-file="UseCaseSlider.tsx">Company</Text.P>
          </div>

          <div className="pointer-events-none absolute bottom-[-25px] left-20">
            <Animation url={"/img/fisher.json"} className="w-[180px] lg:mx-0" data-sentry-element="Animation" data-sentry-source-file="UseCaseSlider.tsx" />
          </div>
        </div>
      </div>

      <div className="relative mx-0 cursor-pointer snap-center py-4" tabIndex={0} onClick={() => {
      onChange("/candidates");
    }} data-id="/candidates">
        <div className="relative mx-0 pb-8 text-purple md:px-2">
          <div className={cn("squircle h-[172px] w-60 p-5", {
          [themeStyle.background.active]: initialActiveKey === "/candidates",
          [themeStyle.background.inactive]: initialActiveKey !== "/candidates",
          [themeStyle.background.hover]: initialActiveKey !== "/candidates"
        })}>
            <Text.P data-sentry-element="unknown" data-sentry-source-file="UseCaseSlider.tsx">Candidate</Text.P>
          </div>
          <div className="pointer-events-none absolute bottom-[-80px] left-[-30px]">
            <Animation url={"/img/skydiver.json"} className="w-[422px] lg:mx-0" data-sentry-element="Animation" data-sentry-source-file="UseCaseSlider.tsx" />
          </div>
        </div>
      </div>
    </div>;
};