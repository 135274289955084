"use client";

import * as React from "react";
import { cn } from "@/utils/tw";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>>(({
  className,
  ...props
}, ref) => <AvatarPrimitive.Root ref={ref} className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)} {...props} />);
Avatar.displayName = AvatarPrimitive.Root.displayName;
const AvatarImage = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>>(({
  className,
  ...props
}, ref) => <AvatarPrimitive.Image ref={ref} className={cn("aspect-square h-full w-full object-contain", className)} {...props} />);
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
const AvatarFallback = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Fallback>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>>(({
  className,
  ...props
}, ref) => <AvatarPrimitive.Fallback ref={ref} className={cn("flex h-full w-full items-center justify-center rounded-full bg-muted", className)} {...props} />);
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
export { Avatar, AvatarImage, AvatarFallback };
export const SimpleAvatar = ({
  fallback,
  src,
  avatarClassName,
  noSimplifyString = false,
  ...props
}: {
  fallback: string;
  avatarClassName?: string;
  src?: string | null;
  noSimplifyString?: boolean;
} & Omit<React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>, "children">) => <Avatar {...props} data-sentry-element="Avatar" data-sentry-component="SimpleAvatar" data-sentry-source-file="avatar.tsx">
    {src && <AvatarImage src={src} className={cn("object-contain", avatarClassName)} />}
    <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="avatar.tsx">
      {noSimplifyString ? fallback : fallback.split(" ").map(el => el.charAt(0)).join("")}
    </AvatarFallback>
  </Avatar>;