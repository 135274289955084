import type { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import Image from "next/image";
import Link from "next/link";
import { StickyFooterContent } from "@/components/StickyFooterContent";
import { Button } from "@/components/ui/Button";
import { Divider } from "@/components/ui/Divider";
import { Text } from "@/components/ui/Text";
import { cn } from "@/utils/tw";
import type { NavbarTheme } from "./Nav";
import { SimpleSheet } from "../ui/sheet";
import { ContextButton } from "./ContextButton";
import { themeStyles } from "./Nav";
import { UseCaseSlider } from "./UseCaseSlider";
interface MobileNavProps {
  isSheetOpen: boolean;
  setSheetOpen: (open: boolean) => void;
  theme: NavbarTheme;
  current: string | null;
  router: AppRouterInstance;
  onChangeEnded?: (key: string) => void;
}
export const MobileNav = ({
  isSheetOpen,
  setSheetOpen,
  theme,
  current,
  router,
  onChangeEnded
}: MobileNavProps) => {
  return <div className="lg:hidden" data-sentry-component="MobileNav" data-sentry-source-file="MobileNav.tsx">
      <SimpleSheet open={isSheetOpen} className={cn("h-dvh w-dvw px-0 py-0", themeStyles[theme].nav)} hideClose setOpen={setSheetOpen} position="top" content={<div className="flex h-full flex-col">
            <div className="flex flex-1 flex-col md:gap-4">
              <div className="container flex items-center justify-between pt-8 md:gap-8">
                <ContextButton />
                <Image src="/logo.svg" alt="Vouch" width={108} height={24} className={theme === "beige" ? "" : "brightness-100 invert"} />
                <Button variant="ghost" icon="hamburger" onClick={() => setSheetOpen(false)} rounded size="sm" className={theme === "beige" ? "text-black" : "text-white"} />
              </div>
              <Text.P muted level={3} className="container mt-8">
                I&apos;m a...
              </Text.P>
              <UseCaseSlider initialActiveKey={current || "/"} onChange={key => {
          if (key !== current) {
            router.push(key);
          }
          onChangeEnded?.(key);
          setSheetOpen(false);
        }} theme={theme} />
              <div className="relative px-2">
                <Divider className={`${themeStyles[theme].divide} w-full`} />
              </div>
              <div className={`container flex flex-col gap-4 px-2 ${themeStyles[theme].divide} overflow-auto sm:divide-x-2 md:flex-row md:divide-x-0`}>
                <div className={`space-y-3 ${themeStyles[theme].border} pt-1 md:mr-4 md:mt-4 md:border-r-[1px] md:p-4 md:pr-10`}>
                  <Text.P small muted>
                    Product
                  </Text.P>
                  <Link href="/pricing" className="block" onClick={() => setSheetOpen(false)}>
                    <Text.P>Pricing</Text.P>
                  </Link>
                  <Link href="https://chat.buility.com?cid=661925ba2db8330010127953" target="_blank" className="block" onClick={() => setSheetOpen(false)}>
                    <Text.P>Feedback</Text.P>
                  </Link>
                </div>
                <Divider className={`${themeStyles[theme].divide} w-[120px] md:hidden`} />
                <div className="flex-1 space-y-3 md:ml-4 md:mt-4 md:p-4">
                  <Text.P small muted>
                    Company
                  </Text.P>
                  <Link href="/about" className="block" onClick={() => setSheetOpen(false)}>
                    <Text.P>About</Text.P>
                  </Link>
                  <Link href="/blogs" className="block" onClick={() => setSheetOpen(false)}>
                    <Text.P>Blogs</Text.P>
                  </Link>
                  <a id="open-intercom" className="block" onClick={() => setSheetOpen(false)}>
                    <Text.P>Contact</Text.P>
                  </a>
                </div>
              </div>
            </div>
            <div className="container w-full py-4">
              <StickyFooterContent theme={themeStyles[theme].button.primary} onClose={() => setSheetOpen(false)} />
            </div>
          </div>} data-sentry-element="SimpleSheet" data-sentry-source-file="MobileNav.tsx">
        <Button variant="ghost" className={`gap-0 lg:hidden ${theme === "beige" ? "text-black" : "text-white"}`} icon="hamburger" data-sentry-element="Button" data-sentry-source-file="MobileNav.tsx" />
      </SimpleSheet>
    </div>;
};