import { UserMenu } from "@/app/[lang]/UserMenu";
import { AllListingsButton } from "@/components/Nav/AllListingsButton";
import { ContextButton } from "@/components/Nav/ContextButton";
export const StickyFooterContent = ({
  onClose,
  theme
}: {
  onClose?: () => void;
  theme?: string;
}) => {
  return <div className="flex items-center justify-between gap-2" data-sentry-component="StickyFooterContent" data-sentry-source-file="StickyFooterContent.tsx">
      <div className="flex gap-1">
        <ContextButton data-sentry-element="ContextButton" data-sentry-source-file="StickyFooterContent.tsx" />
        <AllListingsButton className={theme} data-sentry-element="AllListingsButton" data-sentry-source-file="StickyFooterContent.tsx" />
      </div>
      <div className="relative flex items-center gap-4 self-end">
        <UserMenu className={theme} onButtonClick={onClose} data-sentry-element="UserMenu" data-sentry-source-file="StickyFooterContent.tsx" />
      </div>
    </div>;
};