"use client";

import React from "react";
import { cn } from "@/utils/tw";
import * as TooltipBase from "@radix-ui/react-tooltip";
export const Tooltip = ({
  content,
  children,
  contentClass,
  disabled,
  arrowClass
}: {
  arrowClass?: string;
  contentClass?: string;
  disabled?: boolean;
  content?: React.ReactNode;
  children: React.ReactNode;
}) => {
  if (disabled || !content) {
    return children;
  }
  return <TooltipBase.Provider delayDuration={100} data-sentry-element="unknown" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      <TooltipBase.Root data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
        {disabled ? children : <TooltipBase.Trigger asChild>{children}</TooltipBase.Trigger>}
        {!disabled && <TooltipBase.Portal>
            <TooltipBase.Content className={cn("data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-violet11 select-none rounded-md bg-white px-[15px] py-[10px] text-[15px]  shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]", contentClass)} sideOffset={5}>
              {content}
              <TooltipBase.Arrow className={cn(arrowClass, "fill-white")} />
            </TooltipBase.Content>
          </TooltipBase.Portal>}
      </TooltipBase.Root>
    </TooltipBase.Provider>;
};