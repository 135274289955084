"use client";

import type { ButtonProps } from "@/components/ui/Button";
import { useMemo } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Button } from "@/components/ui/Button";
import { useIsClient } from "@/hooks/useIsClient";
import { trpc } from "@/utils/TrpcProvider";
import { cn } from "@/utils/tw";
import { useLocaleProvider } from "../../app/[lang]/LocaleProvider";
export const ContextButton = (props: ButtonProps) => {
  const {
    sourceContext,
    setSourceContext
  } = useLocaleProvider();
  const isClient = useIsClient();
  const pathName = usePathname();
  const id = useMemo(() => {
    try {
      return sourceContext?.url ? new URL(sourceContext.url).pathname.split("/")[2] || "" : "";
    } catch {
      return "";
    }
  }, [sourceContext?.url]);
  trpc.listings.get.useQuery({
    id: id
  }, {
    enabled: !!sourceContext?.type && sourceContext.type === "listing" && !!id,
    onSuccess: data => {
      if (!data) {
        setSourceContext(null);
      }
    }
  });
  trpc.vouches.getPublic.useQuery({
    id: id
  }, {
    enabled: !!sourceContext?.type && sourceContext.type === "vouch" && !!id,
    onSuccess: data => {
      if (!data) {
        setSourceContext(null);
      }
    }
  });
  trpc.business.getPublic.useQuery({
    id: id
  }, {
    enabled: !!sourceContext?.type && sourceContext.type === "company" && !!id,
    onSuccess: data => {
      if (!data) {
        setSourceContext(null);
      }
    },
    onError: () => {
      setSourceContext(null);
    }
  });
  if (!isClient) {
    return null;
  }
  if (sourceContext) {
    let text = "";
    switch (sourceContext.type) {
      case "company":
        text = "Company";
        break;
      case "listing":
        text = "Listing";
        break;
      case "vouch":
        text = "Vouch";
        break;
    }
    const path = sourceContext.url.substring(1).split("/")?.[0];
    if (path && !pathName?.includes(`/${path}`)) {
      return <div className={cn("relative lg:mr-8", props.className)}>
          <Link href={sourceContext.url}>
            <Button variant="grey" icon="leftArrow" iconPlacement="left" rounded {...props}>
              {text}
            </Button>
          </Link>
          <Button size="xs" icon="close" variant="white" className={cn("absolute right-0 top-0 -translate-y-1/2 translate-x-1/2")} onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setSourceContext(null);
        }} />
        </div>;
    }
  }
  return null;
};